const brand = {
  key: 'herculeStudio',
  accessKey: process.env.REACT_APP_HERCULE_STUDIO_ACCESS_KEY,
  name: 'Hercule Studio',
  url: 'https://hercule-studio.com/',
  features: {
    withUidCheck: true,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690809048/certificate-request-page/brands/hercule%20studio/logo.png',
    homePage:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690814225/certificate-request-page/brands/hercule%20studio/home-page_szxoo2.jpg',
    homePageCompressed: null,
    certificateExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690809193/certificate-request-page/brands/hercule%20studio/certificate.png',
    certificateExampleCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690809322/certificate-request-page/brands/hercule%20studio/compressed-certificate.png',
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690809137/certificate-request-page/brands/hercule%20studio/favicon.png',
    uidExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690812118/certificate-request-page/brands/hercule%20studio/uid-check.jpg',
  },
};

export default brand;
