// Lib dependencies
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

// Providers
import LanguageProvider from './i18n/providers/LanguageProvider/LanguageProvider';
import EntityProvider from './providers/EntityProvider';
import MantineProvider from './mantine/providers/MantineProvider';
import ModalsProvider from './mantine/providers/ModalsProvider';
import GlobalStylesProvider from './mantine/providers/GlobalStylesProvider';

// Router
import Router from './router';
import routesPaths from './router/configs/routesPaths.configs';

// Configs
import environment from './configs/environment.configs';

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.initialize(environment.ga.measurementId);
    ReactGA.send({ hitType: 'pageview', page: pathname, title: 'Pageview' });
  }, []);

  return (
    <MantineProvider>
      <ModalsProvider>
        <GlobalStylesProvider />
        {pathname !== routesPaths.notFound ? (
          <LanguageProvider>
            <EntityProvider>
              <Router />
            </EntityProvider>
          </LanguageProvider>
        ) : (
          <Router />
        )}
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App;
