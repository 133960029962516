/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Style
import 'react-lazy-load-image-component/src/effects/blur.css';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
const Image = (props) => <LazyLoadImage {...props} />;

export default Image;
