const environment = {
  trustPlaceApi: {
    baseUrl: process.env.REACT_APP_TRUST_PLACE_API_BASE_URL,
    accessKeys: {
      herculeStudio: process.env.REACT_APP_HERCULE_STUDIO_ACCESS_KEY,
    },
  },
  ipApi: {
    baseUrl: process.env.REACT_APP_IP_API_BASE_URL,
    accessKey: process.env.REACT_APP_IP_API_ACCESS_KEY,
  },
  ga: {
    measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID,
  },
};

export default environment;
