const routesPaths = {
  notFound: '/404',
  home: '/',
  category: '/category',
  product: '/product',
  certification: '/certification',
  success: '/certification/success',
};

export default routesPaths;
